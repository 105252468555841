import React, {useContext, useState} from 'react';
import TitleH2 from './TitleH2';
import ListInputItem from './ListInputItem';
import Btn from './Btn';
import Context from '../context';
import AccessMessage from './AccessMessage';

const Authorization = ({active, setActive}) => {
	const styles = {
	  auth: {
			position: 'absolute',
		  top: 0,
		  width: '100%',
		  textAlign: 'center',
	  },
		ul: {
			padding: 30,
		}
	}
	
	const { modalActiveAuthBtn, setModalActiveAuthBtn, setDisabledBtn } = useContext(Context)
	
	const [inputLogin, setInputLogin] = useState('');
	const [inputPass, setInputPass] = useState('');
	const [activeAccess, setActiveAccess] = useState(false);
	
	const handlerChangeLogin = (event) => {
		setInputLogin(event.target.value);
	};
	
	const handlerChangePass = (event) => {
		setInputPass(event.target.value);
	};
	
	const handlerClear = () => {
		setActive(false)
		setModalActiveAuthBtn(!modalActiveAuthBtn)
		setInputLogin('')
		setInputPass('')
	}
	
	const resultAccessAuth = (inputLogin === 'coBra' && inputPass === 'qwert321')
	
	const handlerAuth = () => {
		if(resultAccessAuth) {
			setActiveAccess(true)
			setTimeout(() => {
				setActiveAccess(false)
				handlerClear()
				setDisabledBtn(false) // Кнопки (Add + Edit + Delete)
			},1000)
		} else {
			setActiveAccess(true)
			setTimeout(() => {
				setActiveAccess(false)
				handlerClear()
			},1000)
		}
	}
	
	return (
		<div className={active ? 'auth show' : 'auth hide'} onClick={() => setActive(false)} style={styles.auth}>
			<TitleH2 text={'Authorization'} />
			<ul style={styles.ul} onClick={(e) => e.stopPropagation()}>
				<ListInputItem label={'login'} type={'text'} value={inputLogin} onChange={handlerChangeLogin}/>
				<ListInputItem label={'password'} type={'pass'} value={inputPass} onChange={handlerChangePass} />
				<Btn classes={'btn'} text={'Login to the system'} onClick={handlerAuth}/>
			</ul>
			<AccessMessage
				activeAccess={activeAccess ? 'show' : 'hide'}
				classes={resultAccessAuth ? 'active-blue' : 'red'}
				title={resultAccessAuth ? 'Access opened' : 'Access closed'}
				desc={resultAccessAuth ? 'Welcome Administrator!' : 'You are not an Administrator!'}/>
		</div>
	);
}

export default Authorization;
