import React, {useContext} from 'react';
import Context from '../context';
import Radium from 'radium';

function SearchPanel() {
	const {searchCompany, setSearchCompany} = useContext(Context);
	
	const styles = {
		div: {
			position: 'relative',
			width: '35%',
		},
		input: {
			borderRadius: 30,
			padding: '0 20px',
			backgroundColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: 2,
			borderColor: '#61dafb',
			width: '100%',
			height: '100%',
		},
		icon: {
			position: 'absolute',
			zIndex: 1,
			top: 8,
			right: 10,
			fontSize: 30,
			color: '#61dafb',
			cursor: 'pointer',
			transition: '0.1s',
			
			':hover': {
				color: '#fff',
			}
		}
	}
	
	const onHandleClearInput = () => {
		setSearchCompany('');
	}
	
	return (
		<div style={styles.div} className={'search-company'}>
			<input
				style={styles.input} type={'text'}
				placeholder={'Search company'}
				value={searchCompany}
				onChange={(e) => setSearchCompany(e.target.value)}
			/>
			<i onClick={onHandleClearInput} style={styles.icon} className="far fa-times-circle"/>
		</div>
	);
}

export default Radium (SearchPanel);
