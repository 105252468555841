import React from 'react';

function ListInformationItems(props) {
	const styles = {
		li: {
			width: '70%',
			height: 50,
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 'auto',
			marginBottom: 30,
			padding: '13px 35px',
			backgroundColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: 2,
			borderColor: '#61dafb',
			borderRadius: 30,
		},
		span: {
			position: 'absolute',
			top: '-6px',
			left: 35,
			color: '#61dafb',
			backgroundColor: '#b8f0ff',
			textTransform: 'uppercase',
			fontSize: '.7rem',
			padding: '0 7px',
		},
	}
	
	return (
		<li style={styles.li}>
			<div className="out">{props.data}</div>
			<span style={styles.span}>{props.label}</span>
		</li>
	);
}

export default ListInformationItems;
