import React from 'react';
import Radium from 'radium';

const PowerBy = () => {
	const styles = {
		div: {
			position: 'absolute',
			right: 40,
			bottom: 30,
			fontSize: '0.8rem',
		},
		a: {
			color: '#61dafb',
			
			':hover': {
				color: '#fff',
			}
		}
	}
	return (
		<div id={'power-by'} style={styles.div}>
			&#169; 2022 | power by&nbsp;
			<a style={styles.a} href="https://adbbox.com/" rel="noreferrer" target='_blank'>adbbox.com</a>
		</div>
	);
};

export default Radium (PowerBy);
