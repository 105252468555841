import React, {useContext, useState} from 'react';
import TitleH2 from './TitleH2';
import ListInputItem from './ListInputItem';
import Btn from './Btn';
import { ref, push, set } from "firebase/database";
import Context from '../context';
import {db} from '../firebase';

const CreateCompany = ({activeCreate, setActiveCreate}) => {
	const styles = {
		create: {
			position: 'absolute',
			top: 0,
			width: '100%',
			textAlign: 'center',
		},
		ul: {
			padding: 30,
		}
	}
	
	const { loadListCompanies, modalActiveCreateBtn, setModalActiveCreateBtn } = useContext(Context)
	
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [id, setId] = useState('');
	
	const handlerCreateCompany = (event) => {
		setName(event.target.value);
	};
	
	const handlerCreateEmail = (event) => {
		setEmail(event.target.value);
	};
	
	const handlerCreatePhone = (event) => {
		setPhone(event.target.value);
	};
	
	const handlerCreateId = (event) => {
		setId(event.target.value);
	};
	
	const handlerClear = () => {
		setActiveCreate(false);
		setModalActiveCreateBtn(!modalActiveCreateBtn);
		setName('');
		setEmail('');
		setPhone('');
		setId('');
	}
	
	const createCompany = () => {
		const newCompanyRef = push(ref(db, 'SpaceX_ListCompany'))
		set(newCompanyRef, {
			name,
			email,
			phone,
			id,
		});
		handlerClear();
		loadListCompanies();
	}
	
	return (
		<div className={activeCreate ? 'create show' : 'create hide'} onClick={() => setActiveCreate(false)} style={styles.create}>
			<TitleH2 text={'Create new Company'} />
			<ul style={styles.ul} onClick={(e) => e.stopPropagation()}>
				<ListInputItem label={'company'} type={'text'} value={name} onChange={handlerCreateCompany}/>
				<ListInputItem label={'email'} type={'email'} value={email} onChange={handlerCreateEmail} />
				<ListInputItem label={'phone'} type={'tel'} value={phone} onChange={handlerCreatePhone}/>
				<ListInputItem label={'id'} type={'text'} value={id} onChange={handlerCreateId}/>
				<Btn classes={'btn'} text={'Create new Company'} onClick={createCompany}/>
			</ul>
		</div>
	);
}

export default CreateCompany;
