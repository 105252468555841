import React from 'react';
import Radium from 'radium';

function ListSortSwitcher(props) {
	const styles = {
		li: {
			cursor: 'pointer',
		},
		i: {
			color: '#b8f0ff',
			
			':hover': {
				color: '#fff',
			}
		},
	}
	
	return (
		<li style={styles.li} onClick={props.onClick}>
			<i style={styles.i} className={props.classes} />
		</li>
	);
}

export default Radium (ListSortSwitcher);
