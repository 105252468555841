import React from 'react';
import spinner from '../image/spinner.svg';

const Loader = () => {
	const styles = {
		div: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			width: '100%',
			height: '100%',
		},
	}
	
	return (
		<div style={styles.div}>
			<p>Loading...</p>
			<img src={spinner} alt="spinner"/>
		</div>
	);
};

export default Loader;
