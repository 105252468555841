import React from 'react';

function AccessMessage(props) {
	const styles = {
		div: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: '100%',
			height: '100%',
			zIndex: 999,
			backgroundColor: 'transparent',
		},
		message: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			zIndex: 999,
			color: '#fff',
			padding: '0 50px 5px',
			borderRadius: 20,
			transform: 'translate(-50%, -50%)',
		},
		h3: {
			textTransform: 'uppercase',
		},
	}
	
	return (
		<div className={props.activeAccess} style={styles.div}>
			<div className={props.classes} style={styles.message}>
				<h3 style={styles.h3}>{props.title}</h3>
				<p>{props.desc}</p>
			</div>
		</div>
	);
}

export default AccessMessage;
