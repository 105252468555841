import React, {useContext} from 'react';
import Context from '../context';
import ListInformationItems from './ListInformationItems';

function ListInformation() {
	const styles =  {
		ul: {
			padding: 30,
		},
	}
	
	const { companyList, toggleStateCompany } = useContext(Context)
	
	return (
		<>
			{companyList.map(item => (
				<ul
					key={item.id+"1"}
					className={toggleStateCompany === item.id ? 'show' : 'hide'}
					style={styles.ul}>
					<ListInformationItems key={item.id+"2"} label={'company'} data={item.name}/>
					<ListInformationItems key={item.id+"3"} label={'email'} data={item.email}/>
					<ListInformationItems key={item.id+"4"} label={'tel'} data={item.phone}/>
					<ListInformationItems key={item.id+"5"} label={'id'} data={item.id}/>
				</ul>
			))}
		</>
		
	);
}

export default ListInformation;
