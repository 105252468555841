import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
	apiKey: "AIzaSyC8vsbFgfh5wxIT3NWYsqrNqzdvd3bpjdk",
	authDomain: "spacex-cargo-react.firebaseapp.com",
	databaseURL: "https://spacex-cargo-react-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "spacex-cargo-react",
	storageBucket: "spacex-cargo-react.appspot.com",
	messagingSenderId: "835153307756",
	appId: "1:835153307756:web:422dc1cff15619a1421049"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
