import React, {useContext} from 'react'
import Context from '../context'
import rocket from '../image/rocket.png';
import SearchPanel from './SearchPanel';
import Btn from './Btn';

const Header = (props) => {
	const styles = {
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			height: 75,
			borderRadius: 40,
			backgroundColor: '#b8f0ff',
			padding: 15,
		},
		logo: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		logo_p: {
			fontSize: 6,
			textTransform: 'uppercase',
			fontWeight: 'bold',
			letterSpacing: 15,
			margin: '5px 0 auto 10px'
		},
		authbtns: {
			width: 260,
			display: 'flex',
			justifyContent: 'space-between',
			border: '2px dotted #61dafb',
			borderRadius: 25,
			padding: 3,
		},
		btns: {
			width: 190,
			display: 'flex',
			justifyContent: 'space-between',
		},
		
	}
	
	const { loadListCompanies, saveListCompaniesPdf, handlerActiveAuth, disabledBtn, handlerActiveCreate } = useContext(Context);
	
	return (
		<header style={styles.header} className={'shadow'}>
			<div className="logo" style={styles.logo}>
				<img src={rocket} alt="rocket-logo" style={{height: 42}}/>
				<div className={'logo-text'}>
					<span style={{margin: 'auto 0 auto 10px', fontSize: 21}}>SpaceX Cargo</span>
					<p style={styles.logo_p}>Logistics</p>
				</div>
				
			</div>
			<SearchPanel />
			<div style={styles.authbtns} className={'admin-btns'}>
				<Btn classes={props.active ? 'btn-admin active-admin' : 'btn-admin'} onClick={handlerActiveAuth} text={'Sing in'} disabled={!disabledBtn}/>
				<Btn classes={props.activeCreate ? 'btn-add fas-btn active-create' : 'btn-add fas-btn'} disabled={disabledBtn}
				     onClick={handlerActiveCreate}
				     text={'New Company'}/>
			</div>
			<div className="btns" style={styles.btns}>
				<Btn classes={'btn-load'} onClick={ loadListCompanies } text={'Load'} />
				<Btn classes={'btn-save'} onClick={ saveListCompaniesPdf } text={'Save'} />
			</div>
		</header>
	);
}

export default Header;
