import React from 'react';
import reactLogo from '../image/built/reactLogo.jpg';
import firebaseDB from '../image/built/firebaseDB.jpg';

const BuiltWith = () => {
	const styles = {
		div: {
			position: 'absolute',
			left: '50%',
			bottom: 20,
			transform: 'translate(-50%, 0)',
			display: 'flex',
			width: 275,
			height: 45,
			padding: '10px 20px',
			borderRadius: 30,
			backgroundColor: '#fff',
			justifyContent: 'space-between',
			margin: 'auto',
		},
		span: {
			margin: 'auto 0',
			fontSize: '0.8rem',
		}
	}
	
	return (
		<div id={'built-with'} style={styles.div} className={'shadow'}>
			<span style={styles.span}>built with:</span>
			<img src={reactLogo} alt="reactLogo"/>
			<img src={firebaseDB} alt="firebaseDB"/>
		</div>
	);
};

export default BuiltWith;
