import React, {useEffect, useState} from 'react';
import {db} from './firebase';
import {child, get, ref, onValue} from 'firebase/database';
import Header from './components/Header';
import Information from './components/Information';
import ListCompanies from './components/ListCompany';
import Context from './context';
import {useReactToPrint} from 'react-to-print';
import './App.scss';

const App = () => {
	const styles = {
		app: {
			fontFamily: 'Comfortaa',
			position: 'absolute',
			zIndex: 1,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			margin: 0,
			padding: 15,
			backgroundColor: '#8de7ff',
		},
		main: {
			marginTop: 20,
			height: 'calc(100% - 100px)',
		},
		wrap: {
			height: '100%',
			display: 'flex',
			justifyContent: 'space-between',
		},
	}
	
	const [loading, setLoading] = useState(true);
	const [companyList, setCompanyList] = useState([]);
	const [toggleStateCompany, setToggleStateCompany] = useState('34554e30e6ac1a4'); // 1я Компания в списке - ID
	const [searchCompany, setSearchCompany] = useState('');
	
	const dataRef = ref(db, 'SpaceX_ListCompany');
	
	const [modalActiveAuth, setModalActiveAuth] = useState(false)
	const [modalActiveAuthBtn, setModalActiveAuthBtn] = useState(false)
	
	const [modalActiveCreate, setModalActiveCreate] = useState(false)
	const [modalActiveCreateBtn, setModalActiveCreateBtn] = useState(false)
	
	const [disabledBtn, setDisabledBtn] = useState(true)
	
	useEffect(() => {
		onValue(dataRef, (snapshot) => {
			setTimeout(() => {
				const data = snapshot.val();
				const companyList = [];
				for(let id in data) {
					companyList.push({id,...data[id]});
				}
				setLoading(false);
				setCompanyList(companyList);
			}, 300);
		})
	}, [dataRef]);
	
	function loadListCompanies() {
		setLoading(true);
		const companyList = [];
		get(child(dataRef, '/')).then((snapshot) => {
			if (snapshot.exists()) {
				setTimeout(() => {
					const data = snapshot.val();
					for(let id in data) {
						companyList.push(data[id]);
					}
					setLoading(false);
					setCompanyList(companyList);
				}, 300);
			} else {
				console.log('No data available');
				setLoading(false);
			}
		}).catch((error) => {
			console.error(error);
		});
	}
	
	const toggleTabCompany = (index) => {
		setToggleStateCompany(index);
	};
	
	const componentRef = React.useRef(null);
	const saveListCompaniesPdf = useReactToPrint({
		content: () => componentRef.current,
	});
	
	const handlerActiveAuth = () => {
		setModalActiveAuth(!modalActiveAuth);
		setModalActiveAuthBtn(!modalActiveAuthBtn);
	}
	
	const handlerActiveCreate = () => {
		setModalActiveCreate(!modalActiveCreate);
		setModalActiveCreateBtn(!modalActiveCreateBtn);
	}
	
	// const handlerActiveDelete = () => {
	// 	setLoading(true);
	// 	onValue(dataRef, (snapshot) => {
	// 		setTimeout(() => {
	// 			const data = snapshot.val();
	// 			console.log(data)
	// 			for(let id in data) {
	// 				if(id === toggleStateCompany) {
	// 					console.log(id + "можно удалить!!!");
	// 				} else {
	// 					console.log('Элементов на удаление нет!!!')
	// 				}
	//
	// 				companyList.push({id,...data[id]});
	// 			}
	// 			setLoading(false);
	// 			setCompanyList(companyList);
	// 		}, 300);
	// 	})
	// 	// dataRefChild.remove();
	// }
	
	return (
		<Context.Provider value={{
			loadListCompanies,
			loading,
			companyList,
			toggleStateCompany,
			searchCompany,
			setSearchCompany,
			toggleTabCompany,
			componentRef,
			saveListCompaniesPdf,
			modalActiveAuth,
			setModalActiveAuth,
			handlerActiveAuth,
			modalActiveAuthBtn,
			setModalActiveAuthBtn,
			disabledBtn,
			setDisabledBtn,
			modalActiveCreate,
			setModalActiveCreate,
			handlerActiveCreate,
			modalActiveCreateBtn,
			setModalActiveCreateBtn,
		}}>
			
			<div style={styles.app} className={'app'}>
				<Header active={modalActiveAuthBtn} activeCreate={modalActiveCreateBtn}/>
				<main style={styles.main}>
					<div style={styles.wrap} className="wrap">
						<ListCompanies/>
						<Information active={modalActiveAuth} setActive={setModalActiveAuth}/>
					</div>
				</main>
			</div>
			
			{/*<Modal active={modalActive} setActive={setModalActive}>*/}
			{/*	<h2>Authorization</h2>*/}
			{/*	<ListInformationItems label={'email'} />*/}
			{/*	<ListInformationItems label={'password'} />*/}
			{/*</Modal>*/}
			
		</Context.Provider>
	);
}

export default App;
