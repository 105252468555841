import React, {Component} from 'react';
import ListCompaniesItemsLi from './ListCompanyItemsLi';

class ListCompanyItems extends Component {
	render() {
		const companyList = (this.props.companyList);
		const activeIcon = (this.props.activeIcon);
		const query = (this.props.searchCompany);
		
		return (
			<ol>
				{(activeIcon === 1) && companyList.sort((a, b) => a.name.localeCompare(b.name))
					.filter(a => a.name.toLowerCase().includes(query.toLowerCase())).map(item => (
					<ListCompaniesItemsLi key={item.id} {...item} />
					))}
				{(activeIcon === 2) && companyList.sort((a, b) => a.name.localeCompare(b.name)).reverse()
					.filter(a => a.name.toLowerCase().includes(query.toLowerCase())).map(item => (
						<ListCompaniesItemsLi key={item.id} {...item} />
					))}
			</ol>
		);
	}
}

export default ListCompanyItems;
