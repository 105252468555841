import React from 'react';
import ListSortSwitcher from './ListSortSwitcher';

function ListSortSwitchers(props) {
	const styles = {
		ul: {
			position: 'absolute',
			top: 30,
			right: 25,
			display: 'flex',
			justifyContent: 'space-between',
			fontSize: 21,
			width: 55,
		}
	}
	
	const arrListSwitchers = [
		{id: 1, iconClass: 'fas fa-sort-alpha-up', selected: false},
		{id: 2, iconClass: 'fas fa-sort-alpha-up-alt', selected: false},
	]
	
	return (
		<ul style={styles.ul} className={'sort-btns'}>
			{arrListSwitchers.map(item => (
				<ListSortSwitcher
					key={item.id}
					classes={props.activeIcon === item.id ? `${item.iconClass} selected` : `${item.iconClass}`}
					onClick={() => props.setActiveIcon(item.id)}
				/>
			))}
		</ul>
	);
}

export default ListSortSwitchers;
