import React, {useContext} from 'react';
import TitleH2 from './TitleH2';
import Loader from './Loader';
import ListInformation from './ListInformation';
import Context from '../context';

function Info(props) {
	const { loading, companyList } = useContext(Context)
	
	const styles = {
		div: {
			height: 'inherit',
		}
	}
	
	return (
		<div className={props.classes} style={styles.div} >
			<TitleH2 text={'Information'} />
			{loading ? <Loader /> : companyList.length ? (
				<ListInformation />
			) : loading ? null : (
				<p>Database is empty!</p>
			)}
		</div>
	);
}

export default Info;
