import React from 'react';
import Radium from 'radium';

function Btn(props) {
	const styles = {
		padding: '0 25px',
		borderRadius: 30,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: '#61dafb',
		backgroundColor: 'transparent',
		letterSpacing: 0.02,
		cursor: 'pointer',
		textDecoration: 'none',
		listStyle: 'none',
		
		':hover': {
			backgroundColor: '#61dafb',
			color: '#fff',
			opacity: 1,
		}
	}
	
	return (
		<button onClick={props.onClick} disabled={props.disabled} style={styles} className={props.classes}>{props.text}</button>
	);
}

export default Radium(Btn);
