import React, {useContext} from 'react';
import Context from '../context';
import Btn from './Btn';
import {db} from '../firebase';
import {child, push, ref} from 'firebase/database';

function ListCompanyItemsLi(props) {
	const styles = {
		li: {
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: 10,
			cursor: 'pointer',
			transition: '0.3s',
		}
	}
	
	const {loadListCompanies, toggleStateCompany, toggleTabCompany, disabledBtn,} = useContext(Context);
	
	const handlerActiveEdit = () => {
		const activeKey = push(child(ref(db), 'SpaceX_ListCompany')).key;
		console.log('Edit' + activeKey);
		loadListCompanies();
	}
	
	const handlerActiveDelete = () => {
		const activeKey = push(child(ref(db), 'SpaceX_ListCompany')).key;
		console.log('Delete' + activeKey);
		loadListCompanies();
	}
	
	return (
		<li style={styles.li}
		    className={toggleStateCompany === props.id ? 'active-menu' : ''}
		    onClick={() => toggleTabCompany(props.id)}
		>
			{props.name}
			<div className="btns">
				<Btn classes={disabledBtn ? 'btn-edit fas-btn': 'btn-edit active-btn fas-btn'} disabled={disabledBtn}
				     onClick={handlerActiveEdit}
				     text={<i className="fas fa-pen-to-square" />}/>
				<Btn classes={disabledBtn ? 'btn-remove fas-btn': 'btn-edit active-btn fas-btn'} disabled={disabledBtn}
				     onClick={handlerActiveDelete}
				     text={<i className="fas fa-trash-alt"/>}/>
			</div>
		</li>
	);
}

export default ListCompanyItemsLi;
