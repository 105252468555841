import React, {useContext, useState} from 'react';
import TitleH2 from './TitleH2';
import Loader from './Loader';
import ListCompaniesItems from './ListCompanyItems';
import ListSortSwitchers from './ListSortSwitchers';
import Context from '../context';

const ListCompany = () => {
	const styles = {
		div: {
			position: 'relative',
			borderRadius: 30,
			width: '30%',
			height: 'inherit',
			padding: '10px 0',
			backgroundColor: '#61dafb',
		},
		listBox: {
			overflow: 'scroll',
			height: 'calc(100% - 85px)',
			margin: '10px 25px',
		},
	}
	
	const { loading, companyList, componentRef, searchCompany } = useContext(Context)
	
	const [selectedIconSort, setSelectedIconSort] = useState(1);
	
	return (
		<div id={'list-companies'} style={styles.div} className={'list-companies shadow'}>
			<TitleH2 text={'Company'}/>
			
			<ListSortSwitchers activeIcon={selectedIconSort} setActiveIcon={setSelectedIconSort}/>
			
			<div className="list-box" style={styles.listBox}>
				{loading ? <Loader /> : companyList.length ? (
					<ListCompaniesItems ref={ componentRef }
					                    searchCompany={searchCompany}
					                    companyList={companyList}
					                    activeIcon={selectedIconSort} />
				) : loading ? null : (
					<p>List is empty!</p>
				)}
			</div>
			
		</div>
	);
}

export default ListCompany;
