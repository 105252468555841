import React from 'react';

function TitleH2(props) {
	const styles = {
		textAlign: 'center',
		textTransform: 'uppercase',
		color: '#fff',
	}
	
	return (
		<h2 style={styles}>{props.text}</h2>
	);
}

export default TitleH2;
