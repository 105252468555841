import React from 'react';

function ListInputItem(props) {
	const styles = {
		li: {
			width: '70%',
			height: 50,
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 'auto',
			marginBottom: 30,
			padding: '13px 35px',
			backgroundColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: 2,
			borderColor: '#61dafb',
			borderRadius: 30,
		},
		span: {
			position: 'absolute',
			top: '-6px',
			left: 35,
			color: '#61dafb',
			backgroundColor: '#b8f0ff',
			textTransform: 'uppercase',
			fontSize: '.7rem',
			padding: '0 7px',
		},
		input: {
			width: '100%',
			borderWidth: 0,
			backgroundColor: '#b7f0ff',
			boxShadow: 'none',
			backgroundImage: 'none',
			color: '#000',
			textAlign: 'center',
		}
	}
	
	return (
		<li style={styles.li}>
			<span style={styles.span}>{props.label}</span>
			<input type={props.type} style={styles.input} value={props.value} onChange={props.onChange}/>
		</li>
	);
}

export default ListInputItem;
