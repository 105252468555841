import React, {useContext} from 'react';
import BuiltWith from './BuiltWith';
import Authorization from './Authorization';
import Info from './Info';
import PowerBy from './PowerBy';
import Context from '../context';
import CreateCompany from './CreateCompany';

function Information() {
	const styles = {
		div: {
			position: 'relative',
			width: '70%',
			height: 'inherit',
			padding: '0 0 0 20px',
		},
		wrap: {
			borderRadius: 30,
			height: 'inherit',
			padding: '10px 25px',
			backgroundColor: '#b8f0ff',
		},
		wrapper: {
			position: 'relative',
			width: '100%',
			height: '80%',
		},
	}
	
	const { modalActiveAuth, setModalActiveAuth, modalActiveCreate, setModalActiveCreate} = useContext(Context)
	
	return (
		<div id={'information'} style={styles.div}>
			<div className="info-wrap shadow" style={styles.wrap}>
				<div style={styles.wrapper}>
					<Info classes={(!modalActiveAuth && !modalActiveCreate) ? 'info show' : 'info hide'}/>
					<Authorization active={modalActiveAuth} setActive={setModalActiveAuth} />
					<CreateCompany activeCreate={modalActiveCreate} setActiveCreate={setModalActiveCreate} />
				</div>
				<BuiltWith />
				<PowerBy />
			</div>
		</div>
	);
}

export default Information;
